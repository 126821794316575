<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 집행년도 -->
          <c-datepicker
            label="LBL0010340"
            name="year"
            type="year"
            default="today"
            v-model="searchParam.budgetYear"
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <!-- 부서 -->
          <c-dept
            :plantCd="searchParam.plantCd"
            type="search"
            label="LBLDEPT"
            name="budgetDeptCd"
            :isFirstValue="false"
            v-model="searchParam.budgetDeptCd"
          />
        </div>
      </template>
    </c-search-box>
    <!-- 예산실적 목록 -->
    <c-table
      ref="table"
      title="LBL0010341"
      :merge="grid.merge"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :columnSetting="false"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'budget-compare',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'plantName' },
          { index: 1, colName: 'plantYear' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            style: 'width:150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'budgetYear',
            field: 'budgetYear',
            // 집행년도
            label: 'LBL0010340',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'budgetTypeItemName',
            field: 'budgetTypeItemName',
            // 항목
            label: 'LBLITEMS',
            style: 'width:250px',
            align: 'left',
            sortable: true,
          },
          {
            name: 'budgetPlanAmt',
            field: 'budgetPlanAmt',
            // 예산편성금액(원)
            label: 'LBL0010342',
            style: 'width:150px',
            align: 'right',
            type: 'cost',
            sortable: true,
          },
          {
            name: 'budgetResultAmt',
            field: 'budgetResultAmt',
            // 예산집행금액(원)
            label: 'LBL0010343',
            style: 'width:150px',
            align: 'right',
            type: 'cost',
            sortable: true,
          },
          {
            name: 'exchange',
            field: 'exchange',
            // 잔액(A - B)
            label: 'LBL0010344',
            style: 'width:150px',
            align: 'right',
            type: 'cost',
            sortable: true,
          },
          {
            name: 'exchangePercent',
            field: 'exchangePercent',
            // 예산대비 비율(%)
            label: 'LBL0010345',
            style: 'width:150px',
            align: 'right',
            type: 'cost',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        budgetStatusCd: 'BS00000005',
        budgetDeptCd: '',
        budgetYear: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.budget.compare.list.url;
      // code setting
      this.searchParam.budgetYear = this.$comm.getThisYear();
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
  }
};
</script>
